import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ProjectCard from "../components/projectCard"
import SEO from "../components/seo"
import './mystyles.scss'

class Projects extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Layout
        title="3D Projects"
        subtitle="Archived 3D projects"
      >
        <SEO
          title="3D Projects"
          keywords={[`3D`, `Visualisaties`, `Projecten`, `Animaties`]}
        />
        <section className="section">
          <div className="columns is-mobile is-multiline">
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <div key={node.id} className="column">
                <ProjectCard
                  slug={node.fields.slug}
                  title={node.frontmatter.title}
                  tags={node.frontmatter.tags}
                  excerpt={node.excerpt}
                  fluid={node.frontmatter.cover.childImageSharp.fluid}
                />
              </div>
            ))}
          </div>
        </section>
      </Layout>
    )
  }
}
export default Projects
export const query = graphql`
  query {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/3dprojects/"}}, sort: {fields: [frontmatter___date], order: DESC })
    {
      group(field: frontmatter___tags) {
        fieldValue
      }
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            cover {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
